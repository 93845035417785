import { Button } from 'baseui/button';
import { useEffect } from 'react';
import {
  isRouteErrorResponse,
  useRouteError,
  useNavigate,
} from 'react-router-dom';

export default function ErrorPageBoundary() {
  const error = useRouteError();
  const navigate = useNavigate();

  const isRouteError = isRouteErrorResponse(error);

  // Added a console log for now to track errors in the portal when ErrorPageBoundary happens
  useEffect(() => {
    if (error) {
      console.error('Error captured in ErrorPageBoundary:', error);
    }
  }, [error]);

  return (
    <div className="flex min-h-screen items-center justify-center bg-gray-100 p-4">
      <div className="mx-auto w-[657px] rounded-2xl bg-white p-12 shadow-md">
        <h1 className="mb-12 text-left text-3xl font-medium">
          {isRouteError
            ? `Oops! Error ${error.status}`
            : 'Oops! Something went wrong.'}
        </h1>
        <p className="mb-10 text-left font-medium text-gray-600">
          {isRouteError
            ? error.statusText ||
              'An unexpected error has occurred. Please try again later.'
            : 'An unexpected error has occurred. Please try again later.'}
        </p>
        <div className="flex justify-end space-x-2">
          <Button
            onClick={() => navigate('/')}
            className="h-[42px] w-[140px] text-brand-primary-600"
            size="default"
            kind="tertiary"
          >
            Go back
          </Button>
          <Button
            onClick={() => window.location.reload()}
            className="h-[42px] w-[140px] text-white"
            size="default"
            kind="primary"
          >
            Refresh
          </Button>
        </div>
      </div>
    </div>
  );
}
