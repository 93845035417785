import { Navigate } from 'react-router-dom';
import { z } from 'zod';

const deployedEnvSchema = z.nativeEnum({
  dev: 'dev',
  staging: 'staging',
  prod: 'prod',
} as const);
export const getEnvVars = () => {
  return z
    .object({
      REACT_APP_PORTAL_ENV: deployedEnvSchema.optional().catch(undefined),
    })
    .catch({
      REACT_APP_PORTAL_ENV: undefined,
    })
    .parse(process.env);
};
export const isDevOrStaging = () => {
  const envVars = getEnvVars().REACT_APP_PORTAL_ENV;
  return envVars === 'dev' || envVars === 'staging';
};

export const feature_flags = {
  invoice_mark_payment: {
    dev: true,
    staging: true,
    prod: true,
  },
  centralized_notes: {
    dev: true,
    staging: true,
    prod: true,
    isEnabled: () => {
      const env = getEnvVars().REACT_APP_PORTAL_ENV ?? 'dev';
      return feature_flags.centralized_notes[env];
    },
  },
} as const;

export function EnvironmentFlagged({
  children,
  enableIn,
  redirecTo,
}: {
  children: React.ReactNode;
  enableIn: z.infer<typeof deployedEnvSchema>[];
  redirecTo?: string;
}) {
  const { REACT_APP_PORTAL_ENV: portalEnv } = getEnvVars();
  const isEnabled = enableIn.some((v) => v === portalEnv);

  if (!isEnabled) {
    return <Navigate to={redirecTo ?? '/'} />;
  }
  return <>{children}</>;
}
